import { createSlice } from '@reduxjs/toolkit';
import { get, merge } from 'lodash';
import { fetchCounts as fetchCountsService } from '../../../services/myartprice';
import { EMPTY_OBJECT } from '../../../constants/utils';

const headerSlice = createSlice({
  name: 'header',
  initialState: {
    counts: {},
  },
  reducers: {
    updateCounts(state, action) {
      merge(state.counts, action.payload);
    },
  },
});

/* Selectors */

export const isCountsLoading = state => get(state.header.counts, 'loading', false);
export const getArtistsCount = state => get(state.header.counts, 'artists', 0);
export const getArtistCountDetails = state => get(state.header.counts, 'details.artists', EMPTY_OBJECT);
export const getLotsCount = state => get(state.header.counts, 'lots', 0);
export const getStoreCount = state => get(state.header.counts, 'store', 0);
export const getTotalCount = state => getArtistsCount(state) + getLotsCount(state) + getStoreCount(state);

/* Actions */

export const { updateCounts } = headerSlice.actions;

export const fetchCounts = () => async (dispatch, getState) => {
  const isLoading = isCountsLoading(getState());
  if (!isLoading) {
    try {
      dispatch(updateCounts({ loading: true }));
      const counts = await fetchCountsService();
      dispatch(updateCounts(counts));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(updateCounts({ loading: false }));
    }
  }
};

export default headerSlice.reducer;
