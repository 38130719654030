import { areThemesLoading } from '../../selectors/subscriptions/physicproducts';
import { get } from '../../../services/wallet/physicproduct';

export const UPDATE_PHYSICPRODUCTS = 'subscriptions/UPDATE_PHYSICPRODUCTS';
export const updatePhysicproducts = ({ physicproducts, loading, error }) => ({
  type: UPDATE_PHYSICPRODUCTS,
  payload: {
    data: physicproducts,
    loading,
    error,
  },
});

export const fetchPhysicproducts =
  ({ filter, image, promocode, quantity }) =>
  async (dispatch, getState) => {
    const state = getState();
    if (areThemesLoading(state)) return;
    try {
      dispatch(updatePhysicproducts({ loading: true }));
      const physicproducts = await get({ filter, image, promocode, quantity });

      dispatch(updatePhysicproducts({ loading: false, physicproducts }));
    } catch (error) {
      dispatch(updatePhysicproducts({ loading: false, error }));
    }
  };
