/* eslint-disable no-param-reassign */

import { get, isEmpty, uniq } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { getFree as getFreeService, getKnowledge as getKnowledgeService, getCollaborations as getCollaborationsService, getTop10 as getTop10Service, getCa as getCaService } from '../../../services/artdbweb/artist';
import { classifiedsForArtist } from '../../../services/wallet/classified';
import { articlesForArtist } from '../../../services/wallet/ami';
import { storesForArtist } from '../../../services/wallet/store';
import { internalAxios } from '../../../utils/axios/internal';
import { EMPTY_ARRAY } from '../../../constants/utils';

const artistSlice = createSlice({
  name: 'artist',
  initialState: {},
  reducers: {
    UpdateFiltersResultCount(state, action) {
      state.filtersResultCount = action.payload;
    },
    updateCollaborations(state, action) {
      state.collaborations = action.payload;
    },
    updateArticles(state, action) {
      state.articles = action.payload;
    },
    updateKnowledge(state, action) {
      state.knowledge = action.payload;
    },
    updateTop10(state, action) {
      state.top10 = action.payload;
    },
    updateCa(state, action) {
      state.ca = action.payload;
    },
    updateFree(state, action) {
      state.free = action.payload;
    },
    updateAilsSeen(state, action) {
      state.ailsSeen = uniq([...(state.ailsSeen || []), ...action.payload]);
    },
    updateStores(state, action) {
      state.stores = action.payload;
    },
    updateClassifieds(state, action) {
      state.classifieds = action.payload;
    },
  },
});

export const getFree = state => get(state.artist, 'free');
export const getArtist = state => get(state.artist, 'artist');
export const getAils = state => get(state.artist, 'ails');
export const getSummaryData = state => get(state.artist, 'summaryData');
export const getLotsFuture = state => get(state.artist, 'lots.future');
export const getLotsPast = state => get(state.artist, 'lots.past');
export const getLots = state => get(state.artist, 'lots');
export const getCategories = state => get(state.artist, 'categories');
export const getCategoriesFuture = state => get(state.artist, 'categories.future');
export const getCategoriesPast = state => get(state.artist, 'categories.past');
export const getCa = state => get(state.artist, 'ca');
export const getCollaborations = state => get(state.artist, 'collaborations');
export const getArticles = state => get(state.artist, 'articles');
export const getStores = state => get(state.artist, 'stores');
export const getClassifieds = state => get(state.artist, 'classifieds');
export const getKnowledge = state => get(state.artist, 'knowledge');
export const getTop10 = state => get(state.artist, 'top10');
export const getAilsSeen = state => get(state.artist, 'ailsSeen', EMPTY_ARRAY);
export const getFiltersResultCount = state => get(state.artist, 'filtersResultCount.count');
export const getFiltersResultSearching = state => get(state.artist, 'filtersResultCount.searching') || false;

export const getMenuEntriesCount = state => {
  let total = 1; // Marketplace by default

  if (!isEmpty(getCategories(state).future)) total += 1;
  if (!isEmpty(getCategories(state).past)) total += 1;
  if (getArtist(state).macro) total += 1;
  if (!isEmpty(getArticles(state)?.items)) total += 1;
  if (!isEmpty(getCollaborations(state))) total += 1;
  if (!isEmpty(getTop10(state)) || !isEmpty(getKnowledge(state))) total += 1;

  return total;
};

export const { updateTop10, updateArticles, updateCollaborations, updateKnowledge, updateCa, updateFree, updateAilsSeen, updateStores, updateClassifieds, UpdateFiltersResultCount } = artistSlice.actions;

export const fetchTop10 = () => async (dispatch, getState) => {
  if (!getTop10(getState())) {
    try {
      const data = await getTop10Service();
      dispatch(updateTop10(data));
    } catch (e) {
      console.error(e);
    }
  }
};

export const fetchFree = () => async (dispatch, getState) => {
  if (!getFree(getState())) {
    try {
      const data = await getFreeService();
      dispatch(updateFree(data));
    } catch (e) {
      console.error(e);
    }
  }
};

export const fetchArticles =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getArticles(getState())) {
      try {
        const data = await articlesForArtist(id);
        dispatch(updateArticles(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchStores =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getStores(getState())) {
      try {
        const data = await storesForArtist(id);
        dispatch(updateStores(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchClassifieds =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getClassifieds(getState())) {
      try {
        const data = await classifiedsForArtist(id);
        dispatch(updateClassifieds(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchCollaborations =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getCollaborations(getState())) {
      try {
        const data = await getCollaborationsService({ id });
        dispatch(updateCollaborations(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchKnowledge =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getKnowledge(getState())) {
      try {
        const data = await getKnowledgeService({ id });
        dispatch(updateKnowledge(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchCa =
  ({ id }) =>
  async (dispatch, getState) => {
    if (!getCa(getState())) {
      try {
        const data = await getCaService({ id });
        dispatch(updateCa(data));
      } catch (e) {
        console.error(e);
      }
    }
  };

export const fetchFiltersResultCount =
  ({ params }) =>
  async (dispatch, getState) => {
    dispatch(UpdateFiltersResultCount({ searching: true }));
    try {
      const artist = getArtist(getState());
      const response = await internalAxios.get('/api/es/lots/search', { params: { ...params, idartist: artist.id } });
      dispatch(UpdateFiltersResultCount({ searching: false, count: response.data.totalCount }));
    } catch (e) {
      dispatch(UpdateFiltersResultCount({ searching: false }));
      console.error(e);
    }
  };

export default artistSlice.reducer;
